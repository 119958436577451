import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { cdaStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let Cupom = class Cupom extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome' },
            { text: 'Entrada', value: 'total_entrada' },
            { text: 'Saída', value: 'total_saida' },
            { text: 'Saldo', value: 'total_saldo' },
        ];
        this.selectPeriodDate = null;
        this.search = '';
        this.loading = false;
        this.items = [];
    }
    async buscar() {
        this.loading = true;
        this.items = await cdaStore.getCadernoCda({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
        this.loading = false;
    }
};
Cupom = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
        },
    })
], Cupom);
export default Cupom;
